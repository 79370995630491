/**
 * Camera
 */

import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import SweetAlert from 'react-bootstrap-sweetalert'
import CameraComponent from "./components/CameraComponent";
import CircularProgress from '@mui/material/CircularProgress';
import LSMediaCheck from '../LSMediaCheck';
class SherpaCamera extends Component {
    state = {
        stream:null,
        showCamera: true,
        dataUri:"",
        loading: true,
        cameraSupportWarning:false,
        warningText: "Camera Not Supported"
    }

    handleTakePhoto = (dataUri)  => {
        // Do stuff with the photo...
        console.log('takePhoto url -->', dataUri);
        this.setState({dataUri: dataUri, showCamera: false, loading: false});
    }

    handleRetake = () => {
        this.setState({showCamera:true, loading: true});
    }

    handleUsePhoto = () => {
        this.props.handleCloseCamera(this.state.dataUri)
    }

    handleCameraSupportError = (e) => {
        console.log(" camera not supported --> ", e);
        if((e+"").includes("Permission denied")) {
            this.setState({cameraSupportWarning: true, showCamera: false, warningText: "Camera Permission denied" })
        } else {
            this.setState({cameraSupportWarning: true, showCamera: false})
        }
    }

    handleClose = () => {
        this.props.handleCancel()
    }
    
    render() {
        let {showCamera, cameraSupportWarning, loading, dataUri} = this.state;
        return (
            <div data-testid={this.props.datatestid} >
            <LSMediaCheck media={{video: true}} popupThemeClass={this.props.popupThemeClass}/>
            {showCamera ? 
                <CameraComponent platform={this.props.platform} takePictureMessage={"Take Picture"} handleTakePhoto={this.handleTakePhoto.bind(this)} handleCameraSupportError={this.handleCameraSupportError}  handleClosePictureCamera={this.handleClose}/>
                : dataUri &&
                <Dialog
                    open={true}
                    className={`take-photo-camera-dialog-popup p-0`}
                    onClose={() => this.handleClose()}
                >
                    {loading &&
                        <div className="d-flex justify-content-center loader-overlay">
                            <CircularProgress className="theame-text" disableShrink/>
                        </div>
                    }
                    <DialogContent className="m-0 p-0 camera-dialog-content ">                          
                        <img src={dataUri} width="100%" height="100%" style={{objectFit: "cover"}}/>
                    </DialogContent>
                    <DialogActions className="d-flex justify-content-center">
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Button color="primary" variant="outlined" className="  mb-3 mr-2 retake-photo" onClick={() => this.handleRetake()}>Retake</Button>
                            <Button color="primary" variant="contained"  className=" mb-3 ml-2 use-photo"  onClick={() => this.handleUsePhoto()}>Use Photo</Button>
                        </div>
                    </DialogActions> 
                </Dialog>
            }
            {cameraSupportWarning &&
                <div className="col-12 p-4">
                    <SweetAlert
                        warning
                        btnSize="sm"
                        customClass="warningText"
                        confirmBtnText="OK"
                        confirmBtnBsStyle="warning"
                        title={this.state.warningText}
                        onConfirm={() => this.handleClose()}
                        >
                    <div className="sweet-alert-text"></div>
                    </SweetAlert>
                </div>
            }
            </div>
        )
    }
}
 
export default SherpaCamera
 