/**
 * App.js Layout Start Here
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
// rct theme provider
import RctThemeProvider from './RctThemeProvider';
// Auth0 theme provider
import Auth0ThemeProvider from './Auth0ThemeProvider';

//registartion app
import {
   AsyncAuth,
	AsyncAppSignIn,
	AsyncAppSignUpLifesherpa,
   AsyncAppAuth0LoginSuccess,
   AsyncAppReactCharts,
   AsyncMyActivityPreview,
   AsyncAppLoginSuccess
} from '../components/AsyncComponent/AsyncComponent'

//Object editor Screens
import AppNewActivity from '../components/Activity/NewActivity';
import AssignActivity from '../components/Activity/AssignActivity'
import AppNewQuickTask from '../components/Activity/NewQuicktask'
import AppNewNotification from '../components/Activity/NewNotification'
// callback component
import Callback from "Components/Callback/Callback";

import SignInFirebaseToken from "../container/SignInFirebaseToken"
import LoginConfirmation from "../components/LoginComponent/LoginConfirmation"
import InitialLaunchScreen from "../components/LaunchScreen/InitialLaunchScreen"
import SuccessScreen from "../components/SuccessComponent/SuccessScreen"
import ResetPassword from "../components/ResetPassword/ResetPassword"
import ResetPasswordSuccess from "../components/ResetPassword/ResetPasswordSuccess"
import ResendVerificationLink from "../components/ResendEmailComponent/ResendVerificationLink"
import EmailVerification from "../components/EmailComponent/EmailVerification"
import EmailVerificationSuccess from "../components/EmailComponent/EmailVerificationSuccess"

import EmailVerificationFailure from "../components/EmailComponent/EmailVerificationFailure"
import ResendEmailVerificationSuccess from "../components/ResendEmailComponent/ResendEmailVerificationSuccess"
// import LoginSuccess from "../components/LoginComponent/LoginSuccess"
import Auth0LoginSuccess from "../components/LoginComponent/Auth0LoginSuccess"
import LoginWithToken from "../components/LoginComponent/LoginWithToken"
import Auth0RedirectSignInPape from "../components/LaunchScreen/Auth0RedirectSignInPape"
import Auth0Login from "../components/LaunchScreen/Auth0Login"
import AcccepPrivacyPolicy from "../components/LoginComponent/AcccepPrivacyPolicy"
import IDPSectionComponent from "../components/LoginComponent/IDPSectionComponent"

import { Helmet } from 'react-helmet';

//Auth0 Handle Authentication

const handleAuthentication = async ({ location }) => {
   if (/access_token|id_token|error/.test(location.hash)) {
      const Auth = await AsyncAuth();
      const auth = new Auth();
      auth.handleAuthentication();
   }
}

/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = ({ component: Component, authUser, ...rest }) =>
   <Route
      {...rest}
      render={props =>
         authUser
            ? <Component {...props} />
            : <Redirect
               to={{
                  // pathname: '/signin',
                  pathname: process.env.REACT_APP === 'ObjectEditor' ? '/activity/general' : '/launchscreen',
                  state: { from: props.location }
               }}
            />}
   />;

class App extends Component {

   render() {
      const { location, match, user } = this.props;
      if (location.pathname === '/') {
         return (<Redirect to={process.env.REACT_APP === 'ObjectEditor' ? '/activity/general' : '/launchscreen'} />);
      }
      if (process.env.REACT_APP === 'ObjectEditor') 
         return (
            <RctThemeProvider location={location}>
               <NotificationContainer />
               <Helmet>
                  <title>LifeSherpa Object Editor</title>
               </Helmet>
               <InitialPath
                  path={`${match.url}app`}
                  authUser={user}
                  component={AppNewActivity}
               />
               <Route path="/activity/assign" component={AssignActivity}/>
               <Route path="/success" component={AsyncAppAuth0LoginSuccess}/>
               <Route path="/activity/general" component={AppNewActivity}/>
               <Route path="/activity/quick-task" component={AppNewQuickTask}/>
               <Route path="/activity/notification" component={AppNewNotification}/>
               <Route path="/v2/activity/general" render={(props) => <AppNewActivity {...props} version="v2" />}/>
               <Route path="/v2/activity/notification" render={(props) => <AppNewNotification {...props} version="v2" />}/>
               <Route path="/react/charts" component={AsyncAppReactCharts}/>
               <Route path="/activity/preview" component={AsyncMyActivityPreview}/>
               <Route path="/callback" render={(props) => {
                  return <Callback {...props} />
               }} />
            </RctThemeProvider>
         );
      else
         return (
            <Auth0ThemeProvider location={location}>
               <NotificationContainer />
               <InitialPath
                  path={`${match.url}app`}
                  authUser={user}
                  component={InitialLaunchScreen}
               />
               <Route path="/launchscreen" component={InitialLaunchScreen}/>
               <Route path="/acceptpolicy" component={AcccepPrivacyPolicy}/>
               <Route path="/login" component={LoginWithToken}/>
               <Route path="/auth0loginsuccess" component={SuccessScreen}/>
               <Route path="/auth0login" component={Auth0Login}/>
               <Route path="/signin" component={Auth0RedirectSignInPape}/>
               <Route path="/signinwithEmail" component={AsyncAppSignIn}/>
               <Route path="/loginsuccess" component={AsyncAppLoginSuccess}/>
               <Route path="/signinwithtoken" component={SignInFirebaseToken} />
               <Route path="/signuplifesherpa" component={AsyncAppSignUpLifesherpa}/>
               <Route path="/welcome" component={LoginConfirmation} />
               <Route path="/resetpassword" component={ResetPassword}/>
               <Route path="/resendverificationlinksuccess" component={ResendEmailVerificationSuccess} />
               <Route path="/emailverificationsuccess" component={EmailVerificationSuccess} />
               <Route path="/emailverificationfailure" component={EmailVerificationFailure} />
               <Route path="/emailverify" component={EmailVerification} />
               <Route path="/resendverificationlink" component={ResendVerificationLink} />
               <Route path="/resetpasswordsuccess" component={ResetPasswordSuccess}/>
               <Route path="/action-handler" component={ResetPassword}/>
               <Route path="/success" component={Auth0LoginSuccess}/>
               <Route path="/selectoption" component={IDPSectionComponent}/>
               <Route path="/callback" render={async (props) => {
                  await handleAuthentication(props);
                  return <Callback {...props} />
               }} />
            </Auth0ThemeProvider>
         );
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { user } = authUser;
   return { user };
};

export default connect(mapStateToProps)(App);
