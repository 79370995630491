
let config = null;
let rctSessionWrapper = null;
let formgpBg = null;
let headerTextcolor = null;

if (process.env.REACT_APP === 'DES'){
    rctSessionWrapper = "rct-session-wrapper"
    formgpBg = ""
    headerTextcolor = "text-white"
} else {
    rctSessionWrapper = "rct-session-wrapper-ls"
    formgpBg = "bg-white"
    headerTextcolor = "text-gray"
}


if (process.env.SERVER_MODE === 'production') {
    config = {
       //Production:
        baseURL: 'https://us-central1-project-5436648267978138178.cloudfunctions.net/api/v5',
        commonBaseURL: 'https://us-central1-project-5436648267978138178.cloudfunctions.net/api/V5',
        authBaseURL: 'https://us-central1-project-5436648267978138178.cloudfunctions.net/api/V1',
        appUrl: 'http://firebase-registration-app.s3-website-us-east-1.amazonaws.com',
        baseURLForToken: "https://us-central1-project-5436648267978138178.cloudfunctions.net/api",
        API_KEY: 'AIzaSyCD-Tq45VrpVcNRbmIe__AmHVg_lS77VGQ',
        rctSessionWrapper: rctSessionWrapper,
        formgpBg: formgpBg,
        headerTextcolor: headerTextcolor,
        lsPortalAppURL: "https://lsportal.3rbehavioralsolutions.com/",
        storageBucket: 'ls-reg-prod-bucket',
        serverURL: "https://us-central1-project-5436648267978138178.cloudfunctions.net"

    };
} else if (process.env.SERVER_MODE === 'development') {
    config = {
        // Development:
        // baseURL: 'https://us-central1-lifesherpa-development.cloudfunctions.net/api/v3',
        // authBaseURL: 'https://us-central1-lifesherpa-development.cloudfunctions.net/api/V1', 
        // appUrl: 'http://firebase-registration-app-dev.s3-website-us-east-1.amazonaws.com',
        // baseURLForToken: "https://us-central1-lifesherpa-development.cloudfunctions.net/api",
        // API_KEY: 'AIzaSyAjW8UR7hbj7dd-mU86wzd3k2F4n7mosew',
        // rctSessionWrapper: rctSessionWrapper,
        // formgpBg: formgpBg,
        // headerTextcolor: headerTextcolor,

        // Migration:
        baseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/v5',
        commonBaseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/V5',
        authBaseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/V1',
        appUrl: 'http://firebase-registration-app-dev.s3-website-us-east-1.amazonaws.com',
        baseURLForToken: "https://us-central1-ls-config-migration.cloudfunctions.net/api",
        API_KEY: 'AIzaSyCD-Tq45VrpVcNRbmIe__AmHVg_lS77VGQ',
        rctSessionWrapper: rctSessionWrapper,
        formgpBg: formgpBg,
        headerTextcolor: headerTextcolor,
        lsPortalAppURL: "https://dev.lsportal.3rbehavioralsolutions.com/",
        storageBucket: 'ls-reg-dev-bucket',
        serverURL: "https://us-central1-ls-config-migration.cloudfunctions.net"
    };
    
} else {
    config = {
        // Migration:
         baseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/v5',
         commonBaseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/V5',
         authBaseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/V1',
         appUrl: 'http://firebase-registration-app-dev.s3-website-us-east-1.amazonaws.com',
         baseURLForToken: "https://us-central1-ls-config-migration.cloudfunctions.net/api",
         API_KEY: 'AIzaSyCD-Tq45VrpVcNRbmIe__AmHVg_lS77VGQ',
         rctSessionWrapper: rctSessionWrapper,
         formgpBg: formgpBg,
         headerTextcolor: headerTextcolor,
         lsPortalAppURL: "https://dev.lsportal.3rbehavioralsolutions.com/",
         storageBucket: 'ls-reg-mig-bucket',
         serverURL: "https://us-central1-ls-config-migration.cloudfunctions.net"
     };
    
}
 
export default config;
