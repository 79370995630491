/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import QueueAnim from 'rc-queue-anim';
import HeaderComponent from '../Header/HeaderComponent'
import config from '../../config/config'

// redux action
import {
   verifyingEmail,
} from 'Actions';

class EmailVerification extends Component {

	componentDidMount () {
      // let params = queryString.parse(this.props.location.search)
      let userData = {'code': this.props.params.oobCode}
      this.props.verifyingEmail(userData, this.props.history)
    }

   render() {
      const { loading, emailVerified, confirmCode } = this.props;
      
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
            {
               loading &&
                  <div className="d-flex justify-content-center loader-overlay">
                     <CircularProgress className="theame-text" disableShrink/>
                  </div>
            }
            </div>
            <div className={config.rctSessionWrapper}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderComponent/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center text-white">
                              <div className="session-head mb-30">
                                 <p>Verifying Email...</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { user, loading, inviteCode, status,  emailVerified, confirmCode } = authUser;
   console.log(emailVerified, confirmCode)
   return { user, loading, inviteCode, status,  emailVerified, confirmCode }
}

export default connect(mapStateToProps, {
   verifyingEmail,
})(EmailVerification);
