/**
 * Auth0RedirectSignInPage: Component for handling Auth0 redirection after sign-in
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAuth0 } from "@auth0/auth0-react";
import CircularProgress from '@mui/material/CircularProgress';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Form, FormGroup } from 'reactstrap';
import QueueAnim from 'rc-queue-anim';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import HeaderWithCancelButton from '../Header/HeaderWithCancelButton';
import { configureStore } from '../../store';
import {
   resetReduxData
} from 'Actions';
import queryString from 'query-string';

class Auth0RedirectSignInPage extends Component {

   constructor(props) {
      super(props)
      this.interval = null
      this.state = {
         getTokenAccessFailed: "",
         error: "",
         error_description: ""
      }

      this.validateAuth0SignUp = this.validateAuth0SignUp.bind(this)
      this.onConfirm = this.onConfirm.bind(this)
   }

   componentDidMount() {
      this.interval = setInterval(this.validateAuth0SignUp, 5000)
   }

   componentWillUnmount(){
      clearInterval(this.interval)
   }

   getAuth0AccessToken = async (audience, getAccessTokenSilently, user, isAuthenticated) => {
      try {
         const token = await getAccessTokenSilently({ audience: audience, scope: 'read:posts' });
         if (isAuthenticated) {
            clearInterval(this.interval)
            const authResult = { accessToken: token };
            const profileResult = user;
            let store = configureStore();
            store.dispatch({ type: 'LOGIN_USER_SUCCESS', payload: authResult });
            store.dispatch({ type: 'GET_USER_INFO', payload: profileResult });
            console.log("Redirecting to login success screen...");
            window.location.replace(`/loginsuccess`);
         }
      } catch (error) {
         clearInterval(this.interval)
         let { getTokenAccessFailed } = this.state;
         console.error(error);
         const params = queryString.parse(this.props.location.search);
         if (params && params.error && getTokenAccessFailed === '') {
            this.setState({ getTokenAccessFailed: "Something went wrong, try again!", error: params.error, error_description: params.error_description });
         } else if (!getTokenAccessFailed) {
            this.setState({ getTokenAccessFailed: "Something went wrong, try again!", error: "Auth0 Error", error_description: "Something went wrong, try again!" });
         }
      }
   }

   validateAuth0SignUp = () => {
      const { user, isAuthenticated, getAccessTokenSilently } = this.props.auth0;
      const { privacyPolicy, version } = this.props;
      if (privacyPolicy && privacyPolicy.publicSettings && this.state.getTokenAccessFailed === '') {
         this.getAuth0AccessToken(privacyPolicy.publicSettings.audience, getAccessTokenSilently, user, isAuthenticated, version);
      }
   }

   onConfirm = () => {
      this.setState({ getTokenAccessFailed: "", error: "", error_description: "" });
      const mode = localStorage.getItem("appMode");
      const version = localStorage.getItem("version");

      if (mode === "web")
         window.location = "/login?mode=web";
      else if (version == 'v2')
         window.location = `/login?version=${version}`   
      else 
         window.location = `/login`   
   }

   render() {
      const { loading, screenThemeClass, themeLogo, version } = this.props;
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
               {loading &&
                  <div className="d-flex justify-content-center loader-overlay">
                     <CircularProgress className="theme-text" disableShrink />
                  </div>
               }
            </div>
            <div className={screenThemeClass}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderWithCancelButton themeLogo={themeLogo} screenThemeClass={screenThemeClass} location={this.props.location} href={`/success`} />
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center">
                              <Form>
                                 <FormGroup className="mb-10">
                                    <p className="mb-0">Please wait fetching user info...</p>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
            {this.state.getTokenAccessFailed &&
               <SweetAlert
                  warning
                  btnSize="sm"
                  customClass="warningText"
                  show={this.state.getTokenAccessFailed !== ''}
                  confirmBtnText="Back To Token"
                  confirmBtnBsStyle="warning"
                  title={this.state.error ? this.state.error.charAt(0).toUpperCase() + this.state.error.slice(1) : ""}
                  onConfirm={this.onConfirm}
               >
                  {this.state.error_description}
               </SweetAlert>
            }
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser, settings }) => {
   const { loading, privacyPolicy } = authUser;
   const { screenThemeClass, themeLogo } = settings;
   return { loading, privacyPolicy, screenThemeClass, themeLogo };
}

export default connect(mapStateToProps, { resetReduxData })(withAuth0(Auth0RedirectSignInPage));