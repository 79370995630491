/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Form, FormGroup} from 'reactstrap';
import QueueAnim from 'rc-queue-anim';
import HeaderComponent from '../Header/HeaderComponent'
import { detectPlatform } from '../../helpers/helpers';
import config from '../../config/config'


class LoginConfirmation extends Component {
	/**
	 * On User Login
	 */
   componentDidMount(){
      console.log("%c[Login confirmation]%c Redirected on login confirmation screen","color:black","color:green");
   }
   onContinue() {
      const version = localStorage.getItem("version");
      const appMode = localStorage.getItem("appMode")
      if(appMode == "web") {
         window.open(config.lsPortalAppURL, "_self");
      } else if (version == 'v2') {
         window.location.href = detectPlatform() == "Android" ? "lifesherpa-registration://" : "lifesherpa://registration"
      } else {
         window.location.href = "/success"
      }
   }

   render() {
      let {themeData} = this.props;
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className={this.props.screenThemeClass} style={themeData && themeData.loginScreenFillPaternImage ?{backgroundImage:`url(${themeData.loginScreenFillPaternImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}:{}}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                    <HeaderComponent themeLogo={this.props.themeLogo} screenThemeClass={this.props.screenThemeClass}/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center">
                              <div className="session-head mb-30">
                                 <p className="mb-0 base-font-color">Congratulations!</p>
                                 <p className="base-font-color">You have successfully completed your registration.</p>
                                 {process.env.REACT_APP === 'DES' && <p className="text-white">Your DES Provider Contact has been notified of your registration and will be contacting you shortly.</p>}
                                 <p className="base-font-color">We have sent an email to the address you provided. Click the contained link to verify that address. Your account will not be activated until you have completed the verification process.</p>
                                </div>
                              <Form>
                                 <FormGroup>
                                    <Button
                                       className="btn btn-block w-100 login-screen-button"
                                       variant="contained"
                                       size="large"
                                       onClick={() => this.onContinue()}
                                    >
                                       {`Continue ${localStorage.getItem("appMode") == "web" ? "To LS Portal" : ""}`}
                            			</Button>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser, settings}) => {
   const { user, loading , themeData} = authUser;
   const { screenThemeClass, themeLogo } = settings;
   return { user, loading, screenThemeClass, themeLogo, themeData}
}

export default connect(mapStateToProps)(LoginConfirmation);
