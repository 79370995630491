/**
 * Ecommerce Dashboard
 */

import React, { Component } from 'react'
import Webcam from "react-webcam";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import SweetAlert from 'react-bootstrap-sweetalert'
import CircularProgress from '@mui/material/CircularProgress';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import { IconButton } from '@mui/material';

import Button from '@mui/material/Button';

class CameraComponent extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            stream:null,
            dataUri:"",
            showWarning: false,
            cameraLoading:true,
            facingMode: "user"
        }
        this.webcamRef = React.createRef();
    }

handleTakePhoto = () => {
    // Do stuff with the photo...
    if (!this.state.cameraLoading) {
        const imageSrc = this.webcamRef.current.getScreenshot();
        this.props.handleTakePhoto(imageSrc);
    }
}

componentDidMount() {
    setTimeout(() => {
        this.setState({cameraLoading: false});
    },4000)
}
    
handleCameraStart = (stream) => {
    console.log(" Camera Started --> ", stream);
    this.setState({stream: stream, cameraLoading:false});
}

handleClose = () => {
    console.log(" handleClose --> ",this.state.showWarning);
    this.setState({showWarning:""})
}
switchCamera() {
    let {facingMode} = this.state
    if (facingMode === 'user') {
        this.setState({facingMode: { exact: "environment" }})
    } else {
        this.setState({facingMode: "user"})
    }
}

render() {
    let {showWarning, cameraLoading, facingMode} = this.state;
    const {takePictureMessage} = this.props;
    const videoConstraints = {
        facingMode
    };
    console.log("platform :", this.props.platform);
    return (
        <div >
            <Dialog
                open={true}
                className={`camera-popup-dialog p-0`}
                onClose={() => this.handleClose()}
                >
            {cameraLoading &&
                <div className="d-flex justify-content-center loader-overlay">
                    <CircularProgress className="theame-text" disableShrink/>
                </div>
            }
            <DialogContent className={`m-0 p-0 camera-popup-dialog-content fix-min-style-content`}>
                <Webcam
                    audio={false}
                    height={720}
                    ref={this.webcamRef}
                    screenshotFormat="image/jpeg"
                    width={1280}
                    videoConstraints={videoConstraints}
                    onUserMedia={(stream) =>this.handleCameraStart(stream)}
                    onUserMediaError={(e) => this.setState({"Error": e})}
                />
                {showWarning &&
                    <div className="col-12 p-4">
                        <SweetAlert
                            warning
                            btnSize="sm"
                            customClass="warningText"
                            confirmBtnText="OK"
                            confirmBtnBsStyle="warning"
                            title={"Please take a photo to continue"}
                            onConfirm={() => this.handleClose()}
                            >
                        <div className="sweet-alert-text"></div>
                        </SweetAlert>
                    </div>
                }
            </DialogContent>
            <DialogActions className="d-flex justify-content-center camera-popup-dialog-actions">
                <div>
                    <div className="take-picture-message-camera">{takePictureMessage}</div>
                    <div className="d-flex align-items-center justify-content-center mt-1">
                        <div className="outer-circle" onClick={() => this.handleTakePhoto()}>
                            <div className="inner-circle"></div>
                        </div>
                        <Button style={{position: 'absolute', left: 0, color: "black", }} onClick={() => this.props.handleClosePictureCamera()} >Cancel</Button>
                        {this.props.platform === "android" ? <div className='camera-switch'><IconButton size="large" onClick={() => this.switchCamera()}><SwitchCameraIcon/></IconButton></div>  : ""}
                    </div>
                </div> 
            </DialogActions>                
            </Dialog>
        </div>
        )
    }
}
 
export default CameraComponent
 