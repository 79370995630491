/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import CircularProgress from '@mui/material/CircularProgress';
import QueueAnim from 'rc-queue-anim';
import HeaderWithCancelButton from '../Header/HeaderWithCancelButton'
import config from '../../config/config'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import queryString from 'query-string';
import LSInput from "../../components/CustomComponent/LSInput"

// redux action
import {
   getPrivacyPlocy,
   getUserDetailFromToken,
   getUserConnectionByToken
} from 'Actions';

class LoginWithToken extends Component {

   state = {
      isTokenEmpty: null,
      token: '',
      enabledPrivacy: null,
      showError: null,
   }

   componentDidMount(){
      console.group("%c[LoginWithToken]%c Redirected on login with token screen","color:black","color:green");
      let params = queryString.parse(this.props.location.search)
      if (params) {
         localStorage.setItem("version", params.version)
      }
      if (params && params.loginMode && params.connection && params.region) {
         localStorage.setItem("loginMode", params.loginMode)
         localStorage.setItem("connection", params.connection)
         localStorage.setItem("region", params.region)
      }
      if (!params.organization && params.token){
         this.setState({token: params.token.trim()})
      } else if (params.organization && (params.email || params.token)){
         this.onSignInWithUsernameAndPassord()
      } else if (params.organization){
         this.props.getPrivacyPlocy(params)
      }
   }

   handlePrivacypolicy (e) {
      console.log(e.target.checked)
      this.setState({enabledPrivacy: e.target.checked, showError: !e.target.checked})
   }
   handleLSPrivacyPolicy = (value) => {
      if(value) {
         console.log("%c Privacy policy accepted","color:green");
      } else {
         console.log("%c Privacy policy not accepted","color:red");
      }
      this.setState({enabledPrivacy: value, showError: !value})
   }
	/**
	 * On User Login
	 */
   onTokenVerify(e) {
      e.preventDefault();
      if (process.env.REACT_APP === 'DES'){
         if (this.state.token === ''){
            console.log(" Please enter token");
            this.setState({isTokenEmpty: true})
         } else {
            this.props.getUserConnectionByToken(this.state.token, this.props.history);
         }
      } else {
         if (this.state.token === ''){
            console.log(" Please enter token");
            this.setState({isTokenEmpty: true})
         } else if(!this.state.enabledPrivacy){
            console.log(" Please accept privacy policy first");
           this.setState({showError: true})
         }
         else {
            this.props.getUserConnectionByToken(this.state.token, this.props.history);

         }
      }
      
   }

   render() {
      const params = queryString.parse(this.props.location.search)
      const showCancelButton = params && params.mode === "web" ? false : true
      let {themeData, loading} = this.props;
      if (showCancelButton){
         localStorage.setItem("appMode", "app")
      } else {
          localStorage.setItem("appMode", "web")
      }
      const { token, isTokenEmpty, enabledPrivacy, showError } = this.state;
      let isContinueEnabled = (isTokenEmpty === null || showError === null || (token === null || token === '') ) ? true :  isTokenEmpty || showError;
      if(process.env.REACT_APP == 'DES') {
         isContinueEnabled =  (isTokenEmpty === null ||  (token === null || token === '') ) ? true :  isTokenEmpty;
      }
      const showTokenInvalid = this.props.status && this.props.status === 'failed' && this.props.inviteCode && (this.props.inviteCode === token) ? true : false
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
            {
               loading &&
                  <div className="d-flex justify-content-center loader-overlay">
                     <CircularProgress className="theame-text" disableShrink/>
                  </div>
            }
            </div>
            <div className={this.props.screenThemeClass} style={themeData && themeData.loginScreenFillPaternImage ?{backgroundImage:`url(${themeData.loginScreenFillPaternImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}:{}}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderWithCancelButton screenThemeClass={this.props.screenThemeClass} themeLogo={this.props.themeLogo} location={this.props.location} href={`/success`}/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className={"session-body text-center text-white"}>
                              <div className="session-head mb-30">
                                 <p className="base-font-color">The invitation token you have been given is unique to you and should not be shared with any other person.</p>
                                 {process.env.REACT_APP !== 'DES' && <p className="base-font-color">{"Your use of the application is subject to your acceptance of our "} <a target="_blank" href={this.props.privacyPolicy && this.props.privacyPolicy.privacyURL && this.props.privacyPolicy.privacyURL !== '' ? this.props.privacyPolicy.privacyURL : "https://lifesherpapp.com/privacy-policy/"}>privacy policy.</a></p>}
                                 <p className="base-font-color">Please enter the token below and press Continue to complete your one-time registration process and to create your login credentials.</p>
                                </div>
                              <Form onKeyPress={e => {
                                 if (e.key === 'Enter') this.onTokenVerify(e);
                               }}>
                                 <FormGroup className="has-wrapper">
                                    <LSInput
                                       type="text"
                                       value={token}
                                       name="user-token"
                                       autoCapitalize="none"
                                       id="user-token"
                                       className="has-input input-lg text-center"
                                       placeholder="Enter Token Here"
                                       invalid={(isTokenEmpty ? true : false) || showTokenInvalid}
                                       onKeyPress={e => {
                                          if (e.key === 'Enter') this.onTokenVerify(e);
                                        }}
                                       onChange={(event) => this.setState({ token: event.target.value, isTokenEmpty: event.target.value !== '' ? false : true })}
                                    />
                                    {isTokenEmpty ?
                                        <FormFeedback style={{fontSize: 'larger'}}>You must supply a valid invitation token to continue</FormFeedback>
                                        : showTokenInvalid &&
                                        <FormFeedback className="d-block" style={{fontSize: 'larger'}}>{this.props.message ? this.props.message : "The invitation token provided is not valid"}</FormFeedback>
                                    }
                                 </FormGroup>
                                 {process.env.REACT_APP === 'DES' ?
                                    <FormGroup>
                                       <Button
                                          className="btn-block w-100 login-screen-button"
                                          variant="contained"
                                          size="large"
                                          onClick={(e) => this.onTokenVerify(e)}
                                          disabled={isContinueEnabled}
                                       >
                                          Continue
                                          </Button>
                                    </FormGroup>
                                    :
                                    <FormGroup>
                                       <Button
                                          className={`btn-block  w-100 login-screen-button`}
                                          variant="contained"
                                          size="large"
                                          onClick={(e) => this.onTokenVerify(e)}
                                          disabled={isContinueEnabled}
                                       >
                                          Continue
                                          </Button>
                                    </FormGroup>
                                 }
                                 
                                 {process.env.REACT_APP !== 'DES' &&
                                    <FormGroup className={`has-wrapper border pt-2 pb-2 ${config.formgpBg}`}>
                                       <div className="d-flex pl-1 align-items-left text-left pr-1" >
                                          {enabledPrivacy ?
                                             <CheckBoxIcon  onClick={() => this.handleLSPrivacyPolicy(false)} color="primary" style={{ fontSize: "30px", marginRight: "5px", cursor: "pointer" }} />
                                             :
                                             <CheckBoxOutlineBlankIcon  onClick={() => this.handleLSPrivacyPolicy(true)} style={{ fontSize: "30px", marginRight: "5px", color: "rgb(165, 163, 163)", cursor: "pointer" }} />
                                          }
                                          <div className="base-font-color mr-1">
                                          {'I have reviewed & accepted the '}<a target="_blank" href={this.props.privacyPolicy && this.props.privacyPolicy.privacyURL && this.props.privacyPolicy.privacyURL !== '' ? this.props.privacyPolicy.privacyURL : "https://lifesherpapp.com/privacy-policy/"}>privacy policy.</a>
                                          </div>
                                       </div>
                                       {showError && <FormFeedback className="d-block" style={{fontSize: 'larger'}}>Please acknowledge your consent to the Privacy Policy to continue</FormFeedback>}
                                    </FormGroup>
                                 }
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser, settings}) => {
   const { user, loading, privacyPolicy, userOrgFromToken, firebaseToken , themeData} = authUser;
   const inviteCode = userOrgFromToken ? userOrgFromToken.inviteCode : null
   const status = userOrgFromToken ? userOrgFromToken.status : null;
   const message = userOrgFromToken ? userOrgFromToken.message : null;
   
   const { screenThemeClass, themeLogo } = settings;
   return { user, loading, inviteCode, status, privacyPolicy, firebaseToken, screenThemeClass, themeLogo, themeData, message }
}

export default connect(mapStateToProps, {
   getPrivacyPlocy,
   getUserDetailFromToken,
   getUserConnectionByToken
})(LoginWithToken);
